import React from 'react';
import { graphql, Link, useStaticQuery } from "gatsby"
import styled from "styled-components"
import PlayUrlContext from "~/context/play-url"

const Wrapper = styled.div`
  position: relative;
  background: var(--color-dark-mode);
  z-index: 1;
  border-top: 3px solid var(--yellow);

  @media screen and (max-width: 768px) {
    margin: 0px 0px 90px 0px;
  }

  hr {
    height: 1px;
    background-color: var(--yellow);
    border: none;
  }

  .wrapper-social {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px 20px;

    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  .icon-social {
    display: flex;
    align-items: center;
    width: 31px;
    height: 31px;
  }

  .copyright {
    padding: 20px 5px 20px;
    color: var(--yellow);
    max-width: 1280px;
    font-size: 10px;

    @media screen and (max-width: 768px) {
      padding: 0px 0px 20px;
      text-align: center;
    }
  }
`

const Content = styled.div`
  margin: 0 auto;
  bottom: 0;
  width: 100%;
  max-width: 1280px;
  height: 496px;
  display: flex;
  padding: 50px 5px;

  .contact {
    width: 50%;
    height: 100%;
    display: inline-block;

   a > img {
      width: fit-content ;
      height: 45px !important;
    }
  }

  .spacer {
    flex-grow: 1!important;
  }

  .menu {
    width: 50%;
    height: 100%;
    display: inline-flex;
    justify-content: end;

    .col {
      width: 10rem;
      padding: 20px 10px;
      margin: 10px;
    }
  }

  .social {
    display: flex;
    align-items: center;

    div {
      width: 30px;
      height: 30px;
    }
  }

  .text-menu {
    cursor: pointer;
    color: var(--yellow);
    font-size: 0.875rem;
    padding: 5px 0;

    &.-header {
      cursor: text;
      padding: 30px 0;
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const ContentMobile = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    width: 100%;
    margin: 0 auto;
    padding: 30px 20px;

    .logo {
      width: 100%;
      max-width: 200px;
      height: 45px !important;
      margin: 0 auto;
    }

    .menu {
      width: fit-content;
      margin: auto;
      text-align: center;
    }

    .listMenu {
      padding: 10px 0px;

      > a {
        font-size: 1.25rem;
        color: #fff;
      }
    }
  }
`

const Footer = ({ playUrl, langKey, logoWeb }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: {collection: {eq: "common"}}) {
        edges {
          node {
            file
            frontmatter {
              navhome
              navdownload
              navevent
              navgames
              navmode
              playbutton
              logoutbutton
              contact_title
              search_label
              favicon_logo
              logo {
                height
                web_logo
                width
              }
            }
          }
        }
      }
      allMarkdownPage: allMarkdownRemark(filter: {fields: {langKey: {ne: null}}}, limit: 1000) {
        edges {
          node {
            fields {
              slug
              langKey
              }
              frontmatter {
                templateKey
                enable_menu
                icon_menu
                icon_width
                icon_heigh
              }
            }
          }
        }
      contact: allMarkdownRemark(filter: {collection: {eq: "contact"}}) {
        edges {
          node {
            id
            frontmatter {
              social_logo
              social_name
              social_contact
            }
          }
        }
      }
    }
  `)
  const {
    node: { frontmatter },
  } = data.allMarkdownRemark.edges
    .find(item => item.node.file
      .split(".")[1]
      .replace("/", "") === langKey)

  const menuListPath = [
    { path: "/", key: "index", icon: "home", textVal: "navhome", },
    { path: "/game", key: "game", icon: "games", textVal: "navgames", },
    { path: "/event", key: "event", icon: "event", textVal: "navevent", },
    { path: "/download", key: "download", icon: "download", textVal: "navdownload", },
  ]

  const menuList = data.allMarkdownPage.edges
    .filter(items => items.node.fields.langKey === langKey && items.node.frontmatter.enable_menu)
    .map((items) => {
      items.sort = menuListPath.findIndex((e) => e.key === items.node.frontmatter.templateKey)
      return items
    }).sort((a, b) => a.sort - b.sort)

  const contactList = data.contact.edges
  const { categories } = React.useContext(PlayUrlContext)

  return (
    <Wrapper>
      <Content>
        <div className='contact'>
          <Link to={`${langKey === "en" ? "/" : `/${langKey}/`}`}>
            <img src={`${logoWeb ? logoWeb : frontmatter.logo.web_logo}`} alt="" />
          </Link>
          <div className='mt-6'>
            <div className='text-menu'>Chat with Amb Business</div>
            <div className='text-menu'>hello@ambslot.com</div>
            <div className='social'>
              {contactList.length && contactList.map((items, index) => (
                <a href={items.node.frontmatter.social_contact}
                  className='inline-flex items-center mr-3 text-menu'
                  target="_blank"
                  rel="noreferrer"
                  key={index}
                >
                  <div>
                    <img src={items.node.frontmatter.social_logo} alt="" />
                  </div>
                  {items.node.frontmatter.social_name}
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className='spacer'></div>
        <div className='menu'>
          <div className='col'>
            <div className='text-menu -header'>Games</div>
            {categories.map((items, index) => (
              <div className='text-menu' key={index}>
                {items.text}
              </div>
            ))}
          </div>
          <div className='col'>
            <div className='text-menu -header'>News</div>
            <Link to={langKey === 'en' ? `/event/?sort=news` : `/${langKey}/event/?sort=news`}>
              <div className='text-menu'>Game Release</div>
            </Link>
            <Link to={langKey === 'en' ? `/event/?sort=promotion` : `/${langKey}/event/?sort=promotion`}>
              <div className='text-menu'>Promotion</div>
            </Link>
            <Link to={langKey === 'en' ? `/event/?sort=event` : `/${langKey}/event/?sort=event`}>
              <div className='text-menu'>Event</div>
            </Link>
          </div>
        </div>
      </Content>
      <ContentMobile>
        <div className='logo'>
          <Link to={`${langKey === "en" ? "/" : `/${langKey}/`}`}>
            <img src={`/${frontmatter.logo.web_logo}`} alt="" />
          </Link>
        </div>
        <div className='menu'>
          {menuList.map((item) => (
            <div key={item.node.frontmatter.templateKey} className="listMenu">
              <Link
                className="link"
                to={item.node.fields.slug}
                activeClassName='-active'
              >
                <div>
                  {frontmatter[menuListPath.find(items => items.key === item.node.frontmatter.templateKey).textVal]}
                </div>
              </Link>
            </div>
          ))}
        </div>
      </ContentMobile>
      <div className='block' style={{ maxWidth: '1280px', margin: '0 auto' }}>
        <hr />
        <div className='wrapper-social'>
          {contactList.length && contactList.map((items, index) => (
            <div key={index}>
              <a href={items.node.frontmatter.social_contact}
                className='inline-flex items-center mr-3 text-menu'
                target="_blank"
                rel="noreferrer"
              >
                <div className='icon-social'>
                  <img src={items.node.frontmatter.social_logo} alt="" />
                </div>
              </a>
            </div>
          ))}
        </div>
        <div className='copyright'>
          COPYRIGHT © 2018-2022 ALL RIGHTS RESERVED
        </div>
      </div>
    </Wrapper>
  )
}

export default Footer