import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import PlayUrlContext from "~/context/play-url"

const Header = styled.div`
  width: 100%;
  position: fixed;
  z-index: 20;
  max-width: 1280;
  height: 60px;
  top: 0;
  background: transparent;
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  @media screen and (max-width: 768px) {
    display: flex;
  }
`

const PlayButton = styled.a`
  span {
  cursor: pointer;
  margin-right: 8px;

  &::before {
    content: "";
    width: 0px;
    height: 0px;
    border-top: 9px solid transparent;
    border-left: 13px solid var(--color-primary);
    border-bottom: 9px solid transparent;
    }
  }
`

const Hamburger = styled.div`
  .menu-button,
  .menu-button::before,
  .menu-button::after {
    display: block;
    background-color: var(--color-primary);
    position: absolute;
    height: 4px;
    width: 30px;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 2px;
    cursor: pointer;
  }

  .menu-button::before {
    content: '';
    width: 20px;
    margin-top: -8px;
    margin-left: 4px;
  }

  .menu-button::after {
    content: '';
    width: 20px;
    margin-top: 8px;
    margin-left: 4px;
  }
`

const WebLogo = styled.img`
  width: ${props => props.width};
  height: ${props => props.height};
`


export default function HeaderMobile({ langKey, frontmatter, lobbyUrl }) {
  const { toggleMenuMobile, logoWeb } = React.useContext(PlayUrlContext)

  return (
    <Header>
      <Hamburger onClick={toggleMenuMobile}>
        <div className='menu-button'></div>
      </Hamburger>
      <Link to={`${langKey === "en" ? "/" : `/${langKey}/`}`} className='webLogo'>
        <WebLogo src={`/${logoWeb ? logoWeb :frontmatter.logo.web_logo}`} alt="" />
      </Link>
      <div>
        <PlayButton
          className="relative font-bold rounded"
          href={lobbyUrl}
          target="_blank"
        >
          <span className="flex items-center justify-center rounded"></span>
        </PlayButton>
      </div>
    </Header>
  )
}