import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled, { css } from 'styled-components';
import PlayUrlContext from "~/context/play-url"
import { FilterGameComponent } from './filter-game';

const SidebarLayout = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  margin-top: 60px;
  background: var(--bg-primary) 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #00000029;
  z-index: 18;
  transition: 0.5s all, background-color 0s, color 0s;

  @media screen and (max-width: 768px) {
    display: none;
  }

  .header {
    display: flex;
    align-items: center;
    height: 60px;
    width: var(--sidebar-width);
    box-shadow: 0px 3px 6px #00000029;
    padding-left: ${props => props.isShowMenu ? '2.5rem' : '24px'};
    padding-right: 20px;
    justify-content: space-between;
  }

  .webLogo {
    display: none;

    &.-active {
      display: flex;
    }
  }
`;

const SidebarActive = css`
  width: var(--sidebar-width);
  transform: translateX(0px);
`;

const SidebarInActive = css`
  width: 100px;
`;

const IconNav = styled.i`
  background: var(--font-primary);
  mask: url(${props => props.icon}) no-repeat center / contain;
  -webkit-mask: url(${props => props.icon}) no-repeat center / contain;
  width: ${props => props.width};
  height: ${props => props.height};
  margin-right: 3px;
`

const WrapperMenu = styled.div`
  margin-top: 36px;
  overflow: hidden;

  .listMenu {
    width: ${props => props.isShowMenu ? 'var(--sidebar-width)' : 'var(--sidebar-width-inactive)'};
    display: flex;
    flex-direction: column;
    align-items: top;
    transition: 0.5s all;

    a {
      color: var(--font-primary);
    }

    &:hover {
      background: var(--color-primary);
      border-radius: 10px;

      &::before {
      position: absolute;
      content: "";
      left: 0px;
      width: 8px;
      height: ${props => props.isShowMenu ? '50px' : '36px'};
      background: linear-gradient(188deg, var(--yellow) 44%, var(--orange) 100%) no-repeat padding-box;
      opacity: 1;
      }
    }
  }

  .link {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: ${props => props.isShowMenu ? '' : 'center'};
    flex-direction: ${props => props.isShowMenu ? 'row' : 'column'};
    padding: ${props => props.isShowMenu ? '1rem 2rem' : ''};
    height: ${props => props.isShowMenu ? '50px' : '36px'};
    font-size: ${props => props.isShowMenu ? '1rem' : '0.7rem'};
    outline: 0;
    opacity: 0.5;

    &.-active {
      color: var(--font-primary);
      background: var(--color-primary);
      border-radius: 8px;
      opacity: 1;

      &::before {
      position: absolute;
      content: "";
      left: 0px;
      width: 8px;
      height: ${props => props.isShowMenu ? '50px' : '36px'};
      background: linear-gradient(188deg, var(--yellow) 44%, var(--orange) 100%) no-repeat padding-box;
      opacity: 1;
      top: 0;
      }

      i {
        background: var(--font-primary);
      }
    }
  }
`

const SliderBar = ({ langKey, varFilter }) => {
  const data = useStaticQuery(graphql`
  query {
    allMarkdownRemark(filter: {collection: {eq: "common"}}) {
      edges {
        node {
          file
          frontmatter {
            navhome
            navdownload
            navevent
            navgames
            navmode
            playbutton
            logoutbutton
            contact_title
            logo {
              height
              web_logo
              width
            }
          }
        }
      }
    }

    allMarkdownPage: allMarkdownRemark(filter: {fields: {langKey: {ne: null}}}, limit: 1000) {
      edges {
        node {
          fields {
            slug
            langKey
          }
          frontmatter {
            templateKey
            enable_menu
            icon_menu
            icon_width
            icon_heigh
          }
        }
      }
    }
  }
`)
  const {
    node: { frontmatter },
  } = data.allMarkdownRemark.edges
    .find(item => item.node.file
      .split(".")[1]
      .replace("/", "") === langKey)

  const menuListPath = [
    { path: "/", key: "index", icon: "home", textVal: "navhome", },
    { path: "/game", key: "game", icon: "games", textVal: "navgames", },
    { path: "/event", key: "event", icon: "event", textVal: "navevent", },
    { path: "/download", key: "download", icon: "download", textVal: "navdownload", },
  ]

  const menuList = data.allMarkdownPage.edges
    .filter(items => items.node.fields.langKey === langKey && items.node.frontmatter.enable_menu)
    .map((items) => {
      items.sort = menuListPath.findIndex((e) => e.key === items.node.frontmatter.templateKey)
      return items
    }).sort((a, b) => a.sort - b.sort)
  const { isShowMenu } = React.useContext(PlayUrlContext)
  return (
    <SidebarLayout css={isShowMenu ? SidebarActive : SidebarInActive} isShowMenu={isShowMenu}>
      <WrapperMenu isShowMenu={isShowMenu}>
        {menuList.map(item => (
          <div key={item.node.frontmatter.templateKey} className="listMenu">
            <Link
              className="link"
              to={item.node.fields.slug}
              activeClassName='-active'
            >
              <IconNav
                icon={`/${item.node.frontmatter.icon_menu}`}
                width={`${item.node.frontmatter.icon_width}px`}
                height={`${item.node.frontmatter.icon_heigh}px`}
              />
              <div className={`${isShowMenu ? 'block' : 'hidden'}`}>
                {frontmatter[menuListPath.find(items => items.key === item.node.frontmatter.templateKey).textVal]}
              </div>
            </Link>
          </div>
        ))}
        <hr className={`${isShowMenu ? 'w-2/3 mx-auto' : 'hidden'}`} />
        <FilterGameComponent varFilter={varFilter} />
      </WrapperMenu>
    </SidebarLayout>
  )
}

export default SliderBar
