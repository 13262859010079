import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import Toggle from "~/components/dark-mode"
import styled from "styled-components"
import flagEN from "~/images/flag_en.png"
import flagTH from "~/images/flag_th.png"
import searchIcon from "~/images/icon-ios-search.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import GlobalStyle from "~/styles/js/global"
import PlayUrlContext from "~/context/play-url"
import HeaderMobile from './header-mobile'
import SupportIcon from "~/images/icon_support-whitelabel.svg"

const PlayButton = styled.a`
  padding: 6px 15px 8px 35px;
  color: var(--font-primary);
  line-height: 1;
  cursor: pointer;

  &::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 2px;
  background: linear-gradient(45deg, var(--color-primary), var(--color-secondary));
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
  pointer-events: none;
  }

  span {
    top: 8px;
    margin-right: 8px;
    margin-left: 8px;

    &::before {
      content: "";
      width: 0px;
      height: 0px;
      border-top: 9px solid transparent;
      border-left: 13px solid var(--font-primary);
      border-bottom: 9px solid transparent;
      border-radius: 3px 3px;
    }
  }
`

const ModeWrapper = styled.div`
  span {
    margin-right: 8px;
  }
`

const LangButton = styled.div`
  display: flex;
  justify-content: space-evenly;
  color: var(--font-primary) !important;
  width: 58px;
  height: 35px;
  border-radius: 6px;
  backdrop-filter: blur(30px);
  font-size: 14px;
  padding-left: 20px;
  cursor: pointer;
`

const SupportButton = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 10px;
  background: var(--icon-support-color);
  cursor: pointer;
  mask: url(${props => props.questionIcon}) no-repeat center / contain;
  -webkit-mask: url(${props => props.questionIcon}) no-repeat center / contain;
`

const WebLogo = styled.img`
  width: ${props => props.width};
  height: ${props => props.height};
`

const Search = styled.div`
  display: flex;
  width: 100%;
  max-width: 250px;
  background: var(--color-primary) 50%;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
  color: var(--font-primary);
  border-radius: 8px;

  .icon-search {
    width: 15px;
    background: var(--font-primary);
    mask: url(${searchIcon}) no-repeat center / contain;
    -webkit-mask: url(${searchIcon}) no-repeat center / contain;
  }
`

const WrapperLogo = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 0;
  transition: 0.5s all;
  width: var(--sidebar-width);
  box-shadow: 0px 1px 3px #00000017;
  z-index: 20;
  background: transparent !important;

  &.-hide {
    width: var(--sidebar-width-inactive);
  }

  .squash {
    position: absolute;
    margin-left: calc(var(--sidebar-width) - 40px);
    z-index: 20;
    transition: 0.5s all;

    &.-inactive {
      margin-left: calc(var(--sidebar-width-inactive) - 40px);
    }
  }

  .btn-menu {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: transparent linear-gradient(217deg, var(--color-secondary) 0%, var(--color-third) 66%, var(--color-primary) 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 0.8;
    margin-left: calc(var(--sidebar-width) - 40px);
    position: absolute;
    z-index: 20;
    transition: 0.5s all;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0.8);

    .fa-chevron-left {
      color: #ffffff;
    }

    &:hover {
      opacity: 1;
    }

    &.-inactive {
      rotate: 180deg;
      margin-left: calc(var(--sidebar-width-inactive) - 40px);
    }
  }
`

const Header = ({ langKey, langsMenu, handleSupport, iconWeb, lobbyUrl, logoWeb }) => {
  const { isShowMenu, toggleSearch, toggleMenu } = React.useContext(PlayUrlContext)
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: {collection: {eq: "common"}}) {
        edges {
          node {
            file
            frontmatter {
              navdownload
              navevent
              navgames
              navmode
              playbutton
              logoutbutton
              contact_title
              search_label
              favicon_logo
              logo {
                height
                web_logo
                width
              }
            }
          }
        }
      }
      allMarkdownPage: allMarkdownRemark(filter: {fields: {langKey: {ne: null}}}, limit: 1000) {
        edges {
          node {
            fields {
              slug
              langKey
              }
              frontmatter {
                templateKey
                enable_menu
                icon_menu
                icon_width
                icon_heigh
              }
            }
          }
        }
    }
  `)
  const {
    node: { frontmatter },
  } = data.allMarkdownRemark.edges
    .find(item => item.node.file
      .split(".")[1]
      .replace("/", "") === langKey)
  return (
    <>
      <header className="fixed z-20 w-full"
        style={{ transition: "padding-left 0.3s", paddingLeft: `${isShowMenu ? 'var(--sidebar-width)' : 'var(--sidebar-width-inactive)'}` }}>
        <GlobalStyle />
        <WrapperLogo className={`wrapper ${isShowMenu ? '' : '-hide'}`}>
          <Link to={`${langKey === "en" ? "/" : `/${langKey}/`}`} className='webLogo'>
            <WebLogo src={`${logoWeb ? logoWeb : frontmatter.logo.web_logo}`}
              alt="" />
          </Link>
          <div className={`btn-menu ${isShowMenu ? '' : '-inactive'}`}>
            <button onClick={toggleMenu}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
          </div>
        </WrapperLogo>
        <div className="container flex items-center justify-between w-full mx-auto">
          <Search onClick={toggleSearch}>
            <i className='ml-2 mr-3 icon-search' />
            {frontmatter.search_label}
          </Search>
          <div className="flex items-center">
            <div className="mr-5 md:mr-0">
              <Link to={langsMenu.find(item => !item.selected).link} className="flex items-center">
                <LangButton
                  className="flex items-center justify-center"
                  style={{
                    backgroundImage: `url(${langKey === "th" ? flagTH : flagEN})`,
                    backgroundSize: "20px",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "8px center",
                  }}
                >
                  {langKey.toUpperCase()}
                </LangButton>
                <FontAwesomeIcon icon={faChevronDown} className='angle-down' />
              </Link>
            </div>
            {/* <div>
              <SupportButton className="flex"
                onClick={() => handleSupport(true)}
                questionIcon={`${SupportIcon}`}
              />
            </div> */}
            <div className="mr-5">
              <ModeWrapper className="flex items-center">
                <Toggle />
              </ModeWrapper>
            </div>
            <div className='whitespace-nowrap'>
              <PlayButton
                className="relative font-bold text-white rounded"
                href={lobbyUrl}
                target="_blank"
              >
                <span className="absolute top-0 left-0 flex items-center justify-center rounded"></span>
                {frontmatter.playbutton}
              </PlayButton>
            </div>
          </div>
        </div>
      </header>
      <HeaderMobile
        frontmatter={frontmatter}
        langKey={langKey}
        lobbyUrl={lobbyUrl}
      />
    </>
  )
}

Header.propTypes = {
  siteName: PropTypes.string,
  playUrl: PropTypes.string,
}

Header.defaultProps = {
  siteName: ``,
}

export default Header
