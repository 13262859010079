import React, { useEffect, useState } from 'react'
import jwt from 'jsonwebtoken';
import styled from 'styled-components'
import PlayUrlContext from '~/context/play-url'
import searchIcon from '../../static/images/search.png'
import hotIcon from "~/images/icon_fire.png"
import newIcon from "~/images/icon_new.png"
import featureIcon from "~/images/icon_feature.png"
import maintenanceIcon from "~/images/icon_repair.png"
import comingsoonIcon from "~/images/icon_soon.png"
import ButtonCard from '../components/button';
import {
  DEMO_URL,
} from "gatsby-env-variables"

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 19;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  margin-top: 0px;
  padding: 20px;
  position: fixed;
  justify-content: center;

  .search-box {
    width: 100%;
    height: 60px;
    padding: 60px;
    border-image: linear-gradient(0.25turn, #A605F2, rgba(255,0,128), #F8B34C);
    border-bottom: 2px solid transparent;
    border-image-slice: 1;

    input {
      width: 100%;
      height: 60px;
      background: transparent;
    }
  }

  .icon {
    &.search {
      background: url(${searchIcon}) no-repeat;
      background-size: cover;
      width: 18px;
      height: 18px;
      display: block;
    }
  }

  .btn-close {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #9C19FF;
    position: relative;
    z-index: 0;
    cursor: pointer;
    transition: 0.5s all;

    &:hover {
      transform: rotate(-90deg) scale(1.1);
    }

    &::after {
      content: '';
      position: absolute;
      width: 12px;
      border: 1px solid #9C19FF;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      z-index: 1;
      top: 45%;
      left: 15%;
    }

    &::before {
      content: '';
      position: absolute;
      width: 12px;
      border: 1px solid #9C19FF;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      z-index: 1;
      top: 45%;
      left: 15%;
    }
  }
`

const WrapperType = styled.div`
  display: inline-flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 20px;
  padding-top: 10px;

  ul li {
    color: #ACACAC;

    &.-active {
      color: var(--font-primary);
    }
  }
`

const InnerContent = styled.div`
  margin-top: 28px;
`

// const CardWrapper = styled.div`
//   display: grid;
//   gap: 1.5rem;
//   margin-bottom: 70px;
// `

const CardWrapper = styled.div`
  display: grid;
  gap: 1.5rem;
  margin-bottom: 70px;
  height: calc(100vh - 60px);
  overflow-y: scroll;

  @media screen and (max-width: 976px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    height: calc(100% - 75%);
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    height: calc(100% - 80%);
  }
`

const ContentCard = styled.div`
  color: var(--font-primary);
  height: fit-content;

  .bgImage {
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0px 3px 6px #00000029;
    display: inline-block;
    justify-content: center;
    align-items: start;
  }

  .detail-card {
    position: absolute;
    white-space: nowrap;
    z-index: 10;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;
    height: 100%;
    padding: 80px 10px;
    transition: 0.5s all;
    transform: translate(0px, 100px);
    top: 100%;
  }

  &:hover .detail-card {
    transform: translate(0px, 0px);
    top: 0;
  }

  .detailTag {
    position: absolute;
    bottom: 0;
    z-index: 10;
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background: transparent linear-gradient(180deg, #ECF4FF00 0%, #ACCFFFC4 50%, #7BB2FF 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;

    .logo {
      width: 100%;
      max-width: 70px;
      margin: auto;
    }
  }
`

const TagWrapper = styled.div`
  font-size: 10px;
  width: 150px;
  margin: auto;

  div {
    padding: 2px 5px 2px 15px;
    margin: 3px;

    & + div {
      margin-top: 5px;
    }
  }
`

const GameStatusWrapper = styled.div`
  width: 100%;
  max-width: 188px;
  height: 4vw;
  max-height: 53px;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;

  img {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
`

const ShowMore = styled.button`
  width: 264px;
  height: 39px;
  margin: 0 auto 70px;
  font-size: 12px;
  cursor: pointer;
  color: var(--font-primary);
  background: var(--bg-four);

  &:hover {
    background: var(--bg-primary);
    border: 2px solid var(--bg-third);
  }
`

export default function SearchComponent({ langKey }) {
  const { isSearch, toggleSearch, gameData } = React.useContext(PlayUrlContext)
  const [isMobile, setIsMobile] = useState(false)
  const [data, setData] = useState([])
  const [renderData, setRenderData] = useState([])
  const [lazyData, setLazyData] = useState([])
  const [perPage] = useState(24)
  const [skip, setSkip] = useState(0)
  const [currentCate, setCurrentCate] = useState("All Games")
  const [listCate, setListCate] = useState([])
  const [searchGame, setSearchGame] = useState('')

  useEffect(() => {
    if (!searchGame) {
      return setLazyData(renderData)
    } else {
      const res = data.filter((items) => items.name[langKey].toLowerCase().includes(searchGame.toLowerCase()))
      setLazyData(res)
    }
  }, [searchGame])

  useEffect(() => {
    const fetchData = () => {
      const checkMobile =
        /Android|iPod|iPad|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      setIsMobile(checkMobile)
      setData(gameData)
      setRenderData(gameData)
      const start = skip * perPage
      const end = skip * perPage + perPage
      setLazyData(gameData.slice(start, end))
    }
    fetchData();
  }, [gameData])

  useEffect(() => {
    const type = data.map(item => item.type)
    const mapCate = type
      .filter((v, i) => type.indexOf(v) === i)
      .map(item => ({
        text: item,
        icon: item,
      }))
    setListCate(mapCate)
  }, [data])

  useEffect(() => {
    const filterCate = () => {
      const newRenderData = data.filter(item => {
        if (currentCate === "All Games") return item
        else return item.type === currentCate
      })
      setRenderData(newRenderData)
    }

    filterCate()
  }, [currentCate])

  useEffect(() => {
    const start = skip * perPage
    const end = skip * perPage + perPage
    const newLazyData = !skip
      ? [...renderData.slice(start, end)]
      : [...lazyData, ...renderData.slice(start, end)]
    setLazyData(newLazyData)
  }, [skip, renderData])

  const openGames = (value) => {
    if (!value.status && value.demoUrl) {
      const playStaging = jwt.sign({
        link: value.demoUrl + `?lang=${langKey}`,
        provider: value.partner,
        gameName: value.name,
        mode: !isMobile ? 'desktop' : 'mobile',
        lang: langKey,
        backLink: '',
        gameType: value.type,
        prefix: value.prefix,
      }, 'wrong-secret')
      const Features = 'top=0,left=0,height=768,width=1366,scrollbars=no'
      window.open(`${DEMO_URL}?link=${playStaging}`, Features, 'popup')
    }
  }

  const load = () => {
    setSkip(skip + 1)
  }

  return (
    <Wrapper className={`${isSearch ? 'block' : 'hidden'}`}>
      <div className='container mx-auto'>
        <div className='flex items-center search-box'>
          <i className='icon search'></i>
          <input placeholder='Search...' onInput={({ target: { value } }) => setSearchGame(value)} />
          <button className='btn-close' onClick={toggleSearch}>
          </button>
        </div>
        <WrapperType>
          <ul className='flex w-full'>
            <li className={`px-5 cursor-pointer ${currentCate.toLocaleLowerCase() === ('All Games').toLocaleLowerCase() ? '-active' : ''}`}>
              <button onClick={() => setCurrentCate('All Games')}>
                All Games
              </button>
            </li>
            {listCate.map((items, index) => (
              <li className={`px-5 cursor-pointer ${currentCate.toLocaleLowerCase() === items.text.toLocaleLowerCase() ? '-active' : ''}`}
                key={index}>
                <button
                  onClick={() => setCurrentCate(items.text)}>
                  {items.text.charAt(0).toUpperCase() + items.text.slice(1)}
                </button>
              </li>
            ))}
          </ul>
        </WrapperType>
        <InnerContent className="flex justify-between">
          <div className="flex flex-col">
            <CardWrapper className="grid grid-cols-5 gap-6 md:grid-cols-3 lg:grid-cols-6">
              {lazyData.map((value, index) => (
                <ContentCard key={index}>
                  <div className='bottom-0 inline-block h-full bgImage'>
                    <img src={value.image.portraitImage} alt='' />
                    <div className='detailTag'>
                      <img src={value.image.logoImage} className='logo' alt='logo' />
                      <div className='w-full text-center'>{value.name[langKey]}</div>
                      <TagWrapper className={`bottom-0 cursor-pointer items-center flex-wrap justify-center ${value.tag[0] ? "flex" : "hidden"}`}>
                        {value.tag.map((item, idx) => (
                          <div
                            key={idx}
                            className="table rounded"
                            style={{
                              backgroundColor:
                                item === "NEW"
                                  ? "#13C750"
                                  : item === "HOT"
                                    ? "#FC4C60"
                                    : "#7639FF",
                              color: "#fff",
                              backgroundImage:
                                item === "NEW"
                                  ? `url(${newIcon})`
                                  : item === "HOT"
                                    ? `url(${hotIcon})`
                                    : `url(${featureIcon})`,
                              backgroundSize: "10px",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "3px center",
                            }}
                          >
                            {item}
                          </div>
                        ))}
                      </TagWrapper>
                      <GameStatusWrapper
                        style={{
                          display: value.status ? "flex" : "none",
                          color:
                            value.status === 900
                              ? "#FD3C84"
                              : value.status === 901
                                ? "#FD9A00"
                                : "#000",
                        }}
                        className="items-center justify-center font-bold game-status"
                      >
                        <div className="flex items-center justify-center">
                          <div>
                            <img
                              src={
                                value.status === 900
                                  ? maintenanceIcon
                                  : comingsoonIcon
                              }
                              alt=""
                              style={{
                                width: value.status === 901 ? "26px" : "20px",
                              }}
                            />
                          </div>
                          <div>
                            {value.status === 900
                              ? "Maintenance"
                              : value.status === 901
                                ? "Coming Soon!"
                                : ""}
                          </div>
                        </div>
                      </GameStatusWrapper>
                    </div>
                    <div className='detail-card'>
                      <div className='w-full text-center'>{value.name[langKey]}</div>
                      <div
                        className='flex justify-center'
                        style={{
                          marginTop: "30px",
                          width: "100%",
                        }}
                      >
                        <ButtonCard text={'TRY NOW'} onClick={() => openGames(value)} />
                      </div>
                    </div>
                  </div>
                </ContentCard>
              ))}
              <div className='fixed bottom-0 flex w-full mx-auto' style={{ maxWidth: "1280px"}}>
                {skip * perPage + perPage < renderData.length && (
                  <ShowMore onClick={load}>
                    Show More
                  </ShowMore>
                )}
              </div>
            </CardWrapper>
          </div>
        </InnerContent>
      </div>
    </Wrapper>
  )
}